<template>
    <section>

        <AppBodyHeader />

        <b-container>
            <b-row>
                <b-col>

                    <Box :loading="loading">

                        <Form @submit="save">

                            <InputText label="Nome:" type="userName" v-model="user.userName" maxlength="250" required />
                            
                            <InputText label="Login:" type="email" v-model="user.login" maxlength="250" required autocomplete="new-password" />
                            
                            <InputText v-if="!user.userId" label="Senha:" type="password" v-model="user.password" maxlength="15" required autocomplete="new-password" />
                            
                            <Dropdown label="Perfil:" v-model="user.profileId" required load="/profile/dropdown" />

                            <Button type="submit" label="Salvar" icon="box-arrow-in-down" :loading="loading" />
                            <Button label="Voltar" icon="arrow-left" color="secondary" to="/user" />

                        </Form>

                    </Box>

                </b-col>
            </b-row>
        </b-container>

    </section>
</template>

<script>

import AppBodyHeader from '@/components/AppBody/AppBodyHeader'
import Box from '@/components/Box/Box'
import Form from '@/components/Form/Form'
import Dropdown from '@/components/Form/Dropdown'
import InputText from '@/components/Form/InputText'
import Button from '@/components/Button/Button'

export default {

    name: 'UserEditor',

    components: {
        AppBodyHeader,
        Box,
        Form,
        Dropdown,
        InputText,
        Button,
    },

    data() {
        return {

            loading: false,

            user: {
                userId: null,
                profileId: null,
                userName: null,
                login: null,
                password: null,
                insertedIn: null
            }

        }
    },

    mounted() {

        this.load();

    },

    methods: {

        load() {

            let userId = this.$route.params.userId;

            if (userId) {
                
                this.$route.meta.title = 'Carregando Usuário...';
                this.$router.replace({query: {key: Date.now()}})

                this.loading = true;

                this.$http.get(`${this.$store.getters.api}/user/${userId}`)
                    .then(response => {

                        if(response.data.success) {

                            this.user = response.data.data;
                            this.$route.meta.title = `Usuário: ${this.user.userName}`;
                            this.$router.replace({query: {key: Date.now()}})

                        }
                        
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        this.loading = false;
                    });

            }

        },

        save() {

            this.loading = true;

            this.$http.post(`${this.$store.getters.api}/user`, this.user)
                .then(response => {

                    if(response.data.success) {

                        alert('Registro salvado com sucesso.');

                        this.$router.push(`/user/${response.data.data.userId}`);
                        
                        this.load();

                    }

                    else
                        this.loading = false;
                    
                })
                .catch(() => {
                    this.loading = false;
                })
                .finally(() => {
                });

        }

    }

}

</script>