<template>
    <InputContainer>

        <Label :for="id" :text="$props.label" />

        <input
            :type="type"
            :id="id"
            :class="$style.input"
            :value="value"
            :maxlength="maxlength"
            :required="required"
            :readonly="readonly"
            :disabled="readonly"
            :autocomplete="autocomplete"
            v-on:input="$emit('input', $event.target.value)"
        />

    </InputContainer>
</template>

<script>

import { uuid } from 'vue-uuid';

import InputContainer from './InputContainer.vue'
import Label from './Label.vue'

export default {

    name: 'InputText',

    components: {
        InputContainer,
        Label
    },

    props: {

        type: {
            default: 'text'
        },

        label: {
            default: null
        },

        value: {
            default: null
        },

        maxlength: {
            default: null
        },

        required: {
            type: Boolean,
            default: false
        },

        readonly: {
            type: Boolean,
            default: false
        },

        autocomplete: {
            default: 'on'
        }

    },

    data() {
        return {

            id: uuid.v4()

        }
    }

}

</script>

<style lang="scss" module>

@import './input.scss';

</style>